import { combineEpics } from "redux-observable";
import { invitationSaveEffect } from "./invitations-effect";
import { passwordSaveEffect } from "./password-effect";

import {
    preferencesFetchSuccessEffect,
    preferencesResetEffect,
    preferencesSaveEffect,
    preferencesSaveSuccessEffect,
    preferencesSaveEmailNotificationsEffect,
} from "./preferences-effect";

import {
    profileHydrationEffect,
    profileLoginEffect,
    profileSaveEffect,
} from "./profile-effect";

import { reportDownloadEffect, openTimesheetReportEffect } from "./report-effect";
import { subscriptionEffect } from "./subscription-effect";
import { addPaymentEffect } from "./add-payment-effect";
import { cancelSubscriptionEffect } from "./cancel-subscription-effect";
import { updatePaymentEffect } from "./update-payment-effect";

const effects = combineEpics(
    invitationSaveEffect,
    preferencesFetchSuccessEffect,
    preferencesResetEffect,
    preferencesSaveEffect,
    preferencesSaveSuccessEffect,
    preferencesSaveEmailNotificationsEffect,
    passwordSaveEffect,
    profileHydrationEffect,
    profileLoginEffect,
    profileSaveEffect,
    reportDownloadEffect,
    openTimesheetReportEffect,
    addPaymentEffect,
    subscriptionEffect,
    cancelSubscriptionEffect,
    updatePaymentEffect
);

export default effects;