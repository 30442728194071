import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/styles";
import {
	Card,
	CardContent,
	Grid,
	Typography,
	Button,
	useMediaQuery,
	Hidden
} from "@material-ui/core";
import { Page, PageContent, PageHeader, ScrollToTopOnMount } from "frame/components";
import { useCurrentOrganisation } from "utils";
import * as fromJobs from "features/jobs";
import * as fromHealthSafetyForms from "features/health-safety-forms";
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm"; // Timesheets
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"; // Photos
import FlashOnIcon from "@material-ui/icons/FlashOn"; // Incident Records
import HomeCard from "./home-card";
import { ADD_TIMESHEET_ROUTE } from "features/timesheet/timesheet-routes";
import { connect } from "react-redux";
import { onFetchUnconfirmedInductions } from "../home-actions";
import { organisationFetch } from "features/organisations";

const useStyles = makeStyles((theme) => ({
	root: {},
	heading: {
		fontWeight: "bold",
		padding: theme.spacing(2, 0, 1.5, 0)
	},
	linkButton: {
		"padding": theme.spacing(1, 8),
		"color": theme.palette.text.secondary,
		"fontWeight": 600,
		"&:hover": {
			color: theme.palette.brand
		},
		[theme.breakpoints.down("lg")]: {
			padding: theme.spacing(1, 4)
		},
		[theme.breakpoints.down("md")]: {
			padding: theme.spacing(1, 2)
		},
		[theme.breakpoints.down("sm")]: {
			width: "100%"
		}
	},
	emailLink: {
		fontWeight: "bold",
		color: theme.palette.brand
	},
	contact: {
		padding: theme.spacing(2, 0)
	},
	inlineLabel: {
		fontWeight: "bold",
		display: "inline-block",
		marginTop: theme.spacing(3),
		color: "#1c2b4a",
		letterSpacing: 0.1,
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(2),
			fontSize: "1rem"
		}
	},
	inlineNote: {
		display: "inline"
	}
}));

const mapStateToProps = ({ profile, timesheetFormReducer }) => ({
	profile: profile,
	timesheetToggle: timesheetFormReducer.timesheet.toggle
});

const mapDispatchToProps = {
	onFetchUnconfirmedInductionsProp: onFetchUnconfirmedInductions,
	onFetchOrganisations: organisationFetch
};

export const Home = connect(
	mapStateToProps,
	mapDispatchToProps
)(({ profile, onFetchOrganisations, onFetchUnconfirmedInductionsProp, timesheetToggle }) => {
	const classes = useStyles();
	const theme = useTheme();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
	const currentOrganisation = useCurrentOrganisation();

	const name = profile.firstName || "";

	useEffect(() => {
		onFetchUnconfirmedInductionsProp();
	}, [onFetchUnconfirmedInductionsProp]);

	useEffect(() => {
		currentOrganisation === "" && onFetchOrganisations();
	}, [onFetchOrganisations]);

	const homeCardLinksConfig = [
		{
			title: timesheetToggle ? "View Active Timesheet" : "Add Timesheet",
			href: ADD_TIMESHEET_ROUTE,
			icon: AccessAlarmIcon
		},
		{
			title: "Add Photo or Note",
			href: fromJobs.ADD_JOB_PATH,
			icon: PhotoCameraIcon
		},
		{
			title: "Add Incident",
			href: fromHealthSafetyForms.ADD_INCIDENT_PATH,
			icon: FlashOnIcon
		}
	];

	return (
		<Page className={classes.root} title="Home">
			<ScrollToTopOnMount />
			<PageHeader
				breadcrumbs={"Welcome"}
				title={`Welcome ${name}`}
				subtitle={profile.organisation}
			/>
			<PageContent pad>
				<Grid spacing={2} container>
					<Grid item lg={6} md={12} sm={12} xs={12}>
						{homeCardLinksConfig.map(({ title, icon, href }, index) => (
							<HomeCard key={`home-card-links-${index}`} text={title} icon={icon} link={href} />
						))}
					</Grid>
				</Grid>
				<Typography component="h1" variant="h5" className={classes.heading}>
					Contact
				</Typography>
				<Grid spacing={2} container>
					<Grid item lg={6} md={12} sm={12} xs={12}>
						<Card>
							<CardContent>
								<Typography variant="body1">
									Please contact us if you have any questions.
								</Typography>

								<Grid spacing={2} container className={classes.contact}>
									<Grid item sm={5} xs={12}>
										<a href="https://fortherecord.pro/" target="_blank" rel="noopener noreferrer">
											<Button className={classes.linkButton} variant="contained">
												ForTheRecord website
											</Button>
										</a>
									</Grid>
									<Grid item sm={1} xs={12}>
										<Typography variant="h6" align="center">
											or
										</Typography>
									</Grid>
									<Grid item sm={6} xs={12}>
										<Typography variant="h6" align={mobileView ? "center" : "left"}>
											Email Us <br />
											<a className={classes.emailLink} href="mailto:support@fortherecord.pro">
												support@fortherecord.pro{" "}
											</a>
										</Typography>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				</Grid>

				<Hidden smUp>
					<Typography className={classes.inlineLabel}>Add this app to your homescreen</Typography>
					<Typography>
						While in the browser you can add this app to your phones homescreen.
					</Typography>
					<Typography className={classes.inlineLabel}>Android</Typography>
					<Typography className={classes.inlineNote}>
						Tap the menu button and tap Add to homescreen.
					</Typography>
					<Typography className={classes.inlineLabel}>iOS</Typography>
					<Typography className={classes.inlineNote}>
						Tap the Share button on the toolbar. Tap the Add to Home Screen icon in the Share menu.
					</Typography>
				</Hidden>
			</PageContent>
		</Page>
	);
});
