import React, { useContext, useEffect, useState } from "react";
import {
  settingsModal,
  settingsRadio,
  settingsTitle,
  settingsWrapper,
  format_setting_table,
  format_setting_table_first_td
} from "./settings.module.css";

const Settings = ({ visible, callback, settings, settingsCallback }) => {
  return (
    <div>
      {visible && (
        <div
          onClick={() => callback(false)}
          className={settingsWrapper}
          style={{ opacity: visible ? 1 : 0, transition: "opacity 0.3s ease" }}
        >
          <SettingsInner
            settings={settings}
            settingsCallback={settingsCallback}
          ></SettingsInner>
        </div>
      )}
    </div>
  );
};

const SettingsInner = ({ settings, settingsCallback }) => {
  const handleChange = (data, key) => {
    const newSettings = JSON.parse(JSON.stringify(settings));
    newSettings[key] = data;

    settingsCallback(newSettings);
  };

  return (
    <div
      className={settingsModal}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <h4 className={settingsTitle}>Format Settings</h4>

      <div>
        <p>Time format</p>

        <ul className={settingsRadio}>
          <li>
            <input
              type="radio"
              checked={settings.timeFormat == "dec"}
              onChange={() => handleChange("dec", "timeFormat")}
            />
            <label> Decimal (32.93)</label>
          </li>
          <li>
            <input
              type="radio"
              checked={settings.timeFormat == "hm"}
              onChange={() => handleChange("hm", "timeFormat")}
            />
            <label> Hour + min (32 h 55.8 mins)</label>
          </li>
        </ul>
      </div>

      <div>
        <p>Column Settings</p>

        <ul className={settingsRadio}>
          <li>
            <input
              type="checkbox"
              checked={settings.showGeolocation}
              onChange={(e) => handleChange(e.target.checked, "showGeolocation")}
            />
            <label> Show Geolocation link</label>
          </li>
          <li>
            <input
              type="checkbox"
              checked={settings.showStaffType}
              onChange={(e) => handleChange(e.target.checked, "showStaffType")}
            />
            <label> Show Staff Type</label>
          </li>
          <li>
            <input
              type="checkbox"
              checked={settings.showTaskType}
              onChange={(e) => handleChange(e.target.checked, "showTaskType")}
            />
            <label> Show Task Type</label>
          </li>
        </ul>
      </div>

      <div>
        <p>Staff Order</p>

        <ul className={settingsRadio}>
          <li>
            <input
              type="radio"
              checked={settings.staffOrder == "firstname"}
              onChange={() => handleChange("firstname", "staffOrder")}
            />
            <label> First Name</label>
          </li>
          <li>
            <input
              type="radio"
              checked={settings.staffOrder == "lastname"}
              onChange={() => handleChange("lastname", "staffOrder")}
            />
            <label> Last Name</label>
          </li>
        </ul>
      </div>


      <div>
        <p>Leave</p>

        <ul className={settingsRadio}>
          <li>
            <input
              type="radio"
              checked={settings.showLeave == true}
              onChange={() => handleChange(true, "showLeave")}
            />
            <label> Include Leave hours</label>
          </li>
          <li>
            <input
              type="radio"
              checked={settings.showLeave == false}
              onChange={() => handleChange(false, "showLeave")}
            />
            <label> Ignore Leave hours</label>
          </li>
        </ul>
      </div>


    </div>
  );
};

export default Settings;
