import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import NavBar from "features/navigation/components/navbar/navbar-container";
import StaffRoutesContainer from "features/staff/components/staff-routes-container";
import JobsRoutesContainer from "features/jobs/components/jobs-routes-container";
import { AccountRoutes } from "features/account/components/account-routes";
import { ACCOUNT_ROUTE } from "features/account";
import { AdminHome } from "features/home/components/admin-home";
import { InfoRoutes } from "features/home/components/info-routes";
import { TaskRoutes } from "features/home/components/tasks-routes";
import { HomeRoutes } from "features/home/components/home-routes";
import {
	HOME_ROUTE,
	INFO_HOME_ROUTE,
	TASK_HOME_ROUTE,
	ADMIN_HOME_ROUTE
} from "features/home/home-routes";
import { CustomerRoutes, CUSTOMERS_ROUTE } from "features/customers";
import { types as fromHealthSafety, HealthSafetyRoutes } from "features/health-and-safety";
import { ContentBodyProvider } from "frame/providers";
import Header from "./header";
import Footer from "./footer";
import { SITES_ROUTE, SitesRoutes } from "features/sites";
import { ORGANISATIONS_ROUTE, OrganisationRoutes } from "features/organisations";
import { DOCUMENT_PACK_ROUTE } from "../../features/document-pack/routes";
import DocumentPackRoutes from "../../features/document-pack/components/document-pack-routes";
import {
	ADD_TIMESHEET_ROUTE,
	CONFIRM_TIMESHEET_ROUTE,
	LIST_TIMESHEETS_ROUTE,
	ADD_ADMIN_TIMESHEET_ROUTE,
	ADD_SITE_TIMESHEET_ROUTE,
	ADD_SITE_ADMIN_TIMESHEET_ROUTE,
	LIST_SITE_TIMESHEETS_ROUTE,
	NEW_LEAVE_PATH
} from "features/timesheet/timesheet-routes";
import { AddTimesheetForm } from "features/timesheet/components/add-timesheet-form";
import { ConfirmTimesheetForm } from "features/timesheet/components/confirm-timesheet-form";
import { TimesheetList } from "features/timesheet/components/timesheet-list";
import StaffType from "features/staff-type/staff-type";
import TimesheetReport from 'features/timesheet-report/timesheet-report';
import NewLeaveForm from "features/timesheet/components/newLeaveForm";
import { TaskTypeListing } from "features/task-type";

const useStyles = makeStyles(() => ({
	root: {
		height: "100%",
		width: "100%",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden"
	},
	topBar: {
		zIndex: 2,
		position: "relative"
	},
	container: {
		display: "flex",
		flex: "1 1 auto",
		overflow: "hidden"
	},
	navBar: {
		zIndex: 3,
		width: 256,
		minWidth: 256,
		flex: "0 0 auto"
	},
	content: {
		overflowY: "auto",
		flex: "1 1 auto"
	}
}));

function getWindowDimensions() {
	const { innerWidth: width, innerHeight: height } = window;
	return {
		width,
		height
	};
}

const DefaultLayout = ({ openMobile, onToggleSideNavBar, role }) => {
	const classes = useStyles();
	const ref = useRef();

	const [originalScreenHeight] = useState(window.innerHeight);
	const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		function handleResize() {
			setWindowDimensions(getWindowDimensions());
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const showFooter = originalScreenHeight === windowDimensions.height;

	return (
		<ContentBodyProvider value={ref}>
			<div className={classes.root}>
				<Header className={classes.topBar} />
				<div className={classes.container}>
					<NavBar
						className={classes.navBar}
						onToggleSideNavBar={onToggleSideNavBar}
						openMobile={openMobile}
					/>
					<main ref={ref} className={classes.content}>
						<Switch>
							<Route path={ACCOUNT_ROUTE} component={AccountRoutes} />
							<Route path="/quick-pic" component={JobsRoutesContainer} />
							<Route path={fromHealthSafety.HEALTH_SAFETY_ROUTE} component={HealthSafetyRoutes} />
							<Route path="/staff" component={StaffRoutesContainer} />
							<Route path="/staff-type" component={StaffType} />
							<Route path="/timesheet-report" component={TimesheetReport} />
							<Route path="/task-type" component={TaskTypeListing} />
							<Route path={CUSTOMERS_ROUTE} component={CustomerRoutes} />
							<Route path={SITES_ROUTE} component={SitesRoutes} />
							<Route path={DOCUMENT_PACK_ROUTE} component={DocumentPackRoutes} />
							<Route exact path={ADD_TIMESHEET_ROUTE} component={AddTimesheetForm} />
							<Route exact path={ADD_SITE_TIMESHEET_ROUTE} component={AddTimesheetForm} />
							<Route exact path={ADD_ADMIN_TIMESHEET_ROUTE} component={AddTimesheetForm} />
							<Route exact path={NEW_LEAVE_PATH} component={NewLeaveForm} />
							<Route exact path={ADD_SITE_ADMIN_TIMESHEET_ROUTE} component={AddTimesheetForm} />
							<Route exact path={LIST_TIMESHEETS_ROUTE} component={TimesheetList} />
							<Route exact path={LIST_SITE_TIMESHEETS_ROUTE} component={TimesheetList} />
							<Route path={CONFIRM_TIMESHEET_ROUTE} component={ConfirmTimesheetForm} />
							<Route path={ORGANISATIONS_ROUTE} component={OrganisationRoutes} />
							<Route path={INFO_HOME_ROUTE} component={InfoRoutes} />
							<Route path={TASK_HOME_ROUTE} component={TaskRoutes} />
							<Route path={ADMIN_HOME_ROUTE} component={AdminHome} />
							<Route path={HOME_ROUTE} component={HomeRoutes} />
						</Switch>
					</main>
				</div>
				{showFooter && <Footer />}
			</div>
		</ContentBodyProvider>
	);
};

DefaultLayout.propTypes = {
	openMobile: PropTypes.bool.isRequired,
	onToggleSideNavBar: PropTypes.func.isRequired,
	role: PropTypes.string.isRequired
};

export default DefaultLayout;
