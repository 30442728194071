import React, { useState } from "react";
import {
  detailCont,
  detailHeader,
  detail_hours,
  er_col,
  er_row,
  er_title,
  headers,
  row,
  row_col,
  row_col_hours,
  row_col_staff_type,
  row_col_staff_member,
  row_col_time,
  row_col_map,
  row_main,
  row_title,
  row_value,
  svgAlert,
} from "./row.module.css";
import { f2, text_columns, f25, f3 } from "./Detail.module.css";

import Detail from "./Detail";
import DetailToggle from "./DetailToggle";
import DisplayHours from "./DisplayHours";

const Row = (props) => {
  
  const [open, setOpen] = useState(
    props.hours.ers && props.hours.ers.length ? true : false
  );
  return (
    <div className={row} style={{ 'padding-right': props.siteMode ? '0px' : ''}}>
      <div className={row_main}>
        <div className={row_col}>
          <div className={row_title}>Staff member</div>
          <div className={row_value}>{props.staff}</div>
        </div>
        <div className={`${row_col} ${er_col}`}>
          <Errors items={props.hours.ers}></Errors>
        </div>

        <div className={row_col}>&nbsp;</div>
        <div className={row_col}>&nbsp;</div>
        <div className={row_col}>&nbsp;</div>
        
        <div className={row_col_hours}>
          {!props.siteMode ? (
            <div className={row_title}>Gross Hours</div>
          ) : (
            <div className={row_title}>Gross Hours</div>
          )}
          <div className={row_value}>
            <DisplayHours hours={Math.round(props.hours.total * 100) / 100} />
            {/* {Math.round(props.hours.total*100)/100} */}
          </div>
        </div>

        <div className={row_col_hours}>
          <div className={row_title}>Lunch</div>
          <div className={row_value}>
            <DisplayHours hours={props.hours.lunch} />
          </div>
        </div>

        <div className={row_col_hours}>
          <div className={row_title}>Net Hours</div>
          <div className={row_value}>
            <DisplayHours
              hours={TimeRound(props.hours.total - props.hours.lunch)}
            />
          </div>
        </div>
      </div>
      <div className={detailCont}>
        <DetailToggle state={open} fn={setOpen}></DetailToggle>
        {open ? <Details records={props.hours.records} showGeolocation={props.showGeolocation} showStaffType={props.showStaffType} showTaskType={props.showTaskType}></Details> : <div></div>}
      </div>
    </div>
  );
};

const Details = (props) => {
  return (
    <div>
      <div className={headers}>
        <div className={`${detailHeader} ${row_col_time}`}>Start time</div>
        <div className={`${detailHeader} ${row_col_time}`}>End Time</div>
        <div className={`${detailHeader} ${text_columns}`}>Site</div>
        <div className={`${detailHeader} ${text_columns}`}>Notes</div>
        {props.showStaffType && 
          <div className={`${detailHeader} ${text_columns}`}>Staff Type</div>
        }
        {props.showTaskType && 
          <div className={`${detailHeader} ${text_columns}`}>Task Type</div>
        }
        {props.showGeolocation && 
          <div className={`${detailHeader} ${row_col_map}`}>Start Geo</div>
        }
        {props.showGeolocation &&
          <div className={`${detailHeader} ${row_col_map}`}>End Geo</div>
        }
        <div className={`${detailHeader} ${row_col_hours}`}>Gross Hours</div>
        <div className={`${detailHeader} ${row_col_hours}`}>Lunch</div>
        <div className={`${detailHeader} ${row_col_hours}`}>Net Hours</div>
      </div>
      {props.records.map((x, i) => (
        <Detail record={x} key={i} showGeolocation={props.showGeolocation} showStaffType={props.showStaffType} showTaskType={props.showTaskType}></Detail>
      ))}
    </div>
  );
};

const Errors = (props) => {
  const items = props.items;
  if (!items) {
    return <div></div>;
  }
  return (
    <div>
      {items.map((x, i) => (
        <Error er={x} key={i}></Error>
      ))}
    </div>
  );
};

const Error = ({ er }) => {
  return (
    <div className={er_row}>
      <Icon></Icon>
      <div className={er_title}>{er}</div>
    </div>
  );
};

const TimeRound = (t) => {
  return Math.round(t * 100) / 100;
};

const Icon = () => {
  return (
    <svg
      className={svgAlert}
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#FF1D15"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 5.99L19.53 19H4.47L12 5.99M12 2L1 21h22L12 2zm1 14h-2v2h2v-2zm0-6h-2v4h2v-4z" />
    </svg>
  );
};

export default Row;
