import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import JobCard from "./job-card";
import { Typography } from "@material-ui/core";
import { Page, PageContent } from "frame/components";
import { useRouter, isNotNullOrEmpty } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.white,
	},
	heading: {
		marginTop: theme.spacing(4),
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(0),
		},
	},
	body: {
		margin: theme.spacing(3, 0),
	},
	filter: { display: "flex" },
}));

// used in customer summary
const JobCustomerList = ({ quickPicList, currentUserId, role, hasNetwork }) => {
	const classes = useStyles();
	const { match } = useRouter();
	const { customerId } = match.params;

	const customerQuickPicList = quickPicList
		? quickPicList.filter((c) => c.customerId === customerId)
		: [];

	const empty = customerQuickPicList && customerQuickPicList.length <= 0;

	return (
		<Page className={classes.root} title="Photo / Notes">
			<PageContent pad>
				<Typography variant="h2" className={classes.heading}>
				Photo / Notes
				</Typography>

				<div className={classes.body}>
					{!empty &&
						isNotNullOrEmpty(customerQuickPicList) &&
						customerQuickPicList.map((job, index) => (
							<JobCard
								disableCrud
								key={index}
								job={job}
								role={role}
								currentUserId={currentUserId}
								hasNetwork={hasNetwork}
							/>
						))}

					{empty && (
						<Typography variant="body1">
							There are currently no photos or notes.
						</Typography>
					)}
				</div>
			</PageContent>
		</Page>
	);
};

JobCustomerList.propTypes = {
	quickPicList: PropTypes.array.isRequired,
	currentUserId: PropTypes.string.isRequired,
	role: PropTypes.string.isRequired,
	hasNetwork: PropTypes.bool.isRequired,
};

export default JobCustomerList;
