import React from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/styles";
import { useRouter } from "utils";
import { Button, Typography } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import StopIcon from "@material-ui/icons/Stop";
import { CONFIRM_TIMESHEET_ROUTE } from "../timesheet-routes";
import { onAddTimesheetStartTimer } from "../timesheet-actions";

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(2, 0),
	},
	message: {
		fontSize: 14,
		color: theme.palette.success.main,
	},
	icon: {
		marginRight: theme.spacing(1),
		fontSize: 28,
	},
	startButton: {
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2, 4),
		background: theme.palette.success.main,
		borderColor: theme.palette.success.main,
		"&:hover": {
			background: "#388e3c",
			borderColor: "#388e3c",
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "300px",
		},
	},
	stopButton: {
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2, 4),
		background: theme.palette.error.main,
		borderColor: theme.palette.error.main,
		"&:hover": {
			background: theme.palette.error.dark,
			borderColor: theme.palette.error.dark,
		},
		[theme.breakpoints.up("sm")]: {
			maxWidth: "300px",
		},
	},
}));

const mapStateToProps = ({
	profile,
	timesheetFormReducer,
	geolocation,
}) => ({
	currentStaffId: profile.staffId,
	values: timesheetFormReducer,
	latitude: geolocation.latitude,
	longitude: geolocation.longitude,
});

const mapDispatchToProps = {
	onStartTimer: onAddTimesheetStartTimer,
};

export const TimesheetToggleForm = connect(mapStateToProps, mapDispatchToProps)(({
	values,
	currentStaffId,
	onStartTimer,
	latitude,
	longitude,
}) => {
	const classes = useStyles();
	const { history } = useRouter();
	const timerToggle = values.timesheet.toggle;
	const timerMessage = values.timerMessage;

	const handleTimer = () => {
		if (timerToggle) {
			history.push(CONFIRM_TIMESHEET_ROUTE);
		} else {
			onStartTimer({
				staffId: currentStaffId,
				latitude: latitude,
				longitude: longitude,
			});
		}
	};

	return (
		<>
			<Button
				variant="contained"
				color="primary"
				onClick={handleTimer}
				disabled={values.isLoading}
				className={
					timerToggle ? classes.stopButton : classes.startButton
				}
			>
				{timerToggle ? (
					<StopIcon className={classes.icon} />
				) : (
					<PlayArrowIcon className={classes.icon} />
				)}
				{timerToggle ? "Stop" : "Start test"}
			</Button>
			<Typography className={classes.message} variant="body2">
				{timerMessage && timerMessage}
			</Typography>
		</>
	);
});
