import React, {useContext} from "react";
import { Consumer, Context } from "./SettingsContext";


const DisplayHours = ({hours}) => {
    return(
        <Consumer>
            {settings => 
                <DisplayHoursInternal hours={hours} format={settings.timeFormat}/>
            }
        </Consumer>
    )
}

// export const DisplayHoursRaw = (hours) => {
//     const settings = useContext(Context);

//     return (settings);
// }

export const DisplayHoursWithFormat = (hours, format, _nl=false) => {
    if(format.timeFormat == "dec"){
        return Math.round(hours * 100) / 100;
    }else{
        return(formatHM(hours, _nl))
    }
}


const DisplayHoursInternal = ({hours, format}) => {
    

    if(format == "dec"){
        return(<div>{hours}</div>)
    }else{
        return(<div>{formatHM(hours)}</div>)
    }
}

export const parseLunch = (lunchString, hours) => {
    let output;
    let decimalHourValue;


    if(lunchString == "30 minutes"){
      decimalHourValue = 0.5;
    }else if(lunchString == "60 minutes"){
      decimalHourValue = 1;
    }else if(lunchString == "-"){
      decimalHourValue = 0;
    }else{
      console.error(`Unable to recognise lunch value "${lunchString}. This could result in an inaccurate hour total, please contact SafetyWise support."`)
    }
    output = hours - decimalHourValue;

    return output;
  }


const formatHM = (input, _nl=false) => {
    const hours = Math.floor(input);
    const fractionalHours = Math.round((input - hours)*100)/100;
    const minutes = Math.round(Math.round((fractionalHours * 60)*100)/100);
    const nl = _nl ? "\n" : ''
    return `${hours} h ${nl}${minutes} m`
}
export default DisplayHours;