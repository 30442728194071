import {
    PDFColumn,
    PDFColumns,
    PDFDocument,
    PDFTable,
    PDFTableColumn,
    PDFTableRow,
    PDFText
} from 'react-pdfmake';
import React, { useContext } from 'react';

import { DisplayHoursWithFormat } from './components/DisplayHours'
import moment from 'moment'
import { parseLunch } from './components/DisplayHours';
import pdfMake from 'pdfmake/build/pdfmake'
import vfs_fonts from 'pdfmake/build/vfs_fonts'

// import { DisplayHoursRaw } from './components/DisplayHours';






const fonts = {
    Roboto: {
        normal: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bold: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        italics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
        bolditalics: "https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf",
    },
    DMSans: {
        normal: "https://cdn.jsdelivr.net/gh/googlefonts/dm-fonts@9eca355b001e6ea68fa367d4c3cb5d1456c82a04/Sans/Exports/DMSans-Regular.ttf",
        bold: "https://cdn.jsdelivr.net/gh/googlefonts/dm-fonts@9eca355b001e6ea68fa367d4c3cb5d1456c82a04/Sans/Exports/DMSans-Regular.ttf",
        italics: "https://cdn.jsdelivr.net/gh/googlefonts/dm-fonts@9eca355b001e6ea68fa367d4c3cb5d1456c82a04/Sans/Exports/DMSans-Regular.ttf",
        bolditalics: "https://cdn.jsdelivr.net/gh/googlefonts/dm-fonts@9eca355b001e6ea68fa367d4c3cb5d1456c82a04/Sans/Exports/DMSans-Regular.ttf",
    }
};

const styles = {
    title: {
        font: 'DMSans',
        color: '#1c2b4a',
        fontSize: 20
    },
    range: {
        font: 'DMSans',
        color: '#1c2b4a',
        fontSize: 12
    },
    tableHeader: {
        font: 'DMSans',
        fontSize: 9,
        color: "#999"
    },
    tableData: {
        font: 'DMSans',
        color: '#1c2b4a',
        fontSize: 12
    },
    tableDataSmall: {
        font: 'DMSans',
        color: '#1c2b4a',
        fontSize: 9,
    },
    geoLink: {
        font: 'DMSans',
        fontSize: 9,
        decoration: 'underline'
    },
    geoLinkNone: {
        font: 'DMSans',
        fontSize: 9,

    },
    spacer: {
        // color:"ffffff00"
        color: 'white'
    },
    table: {

    }
}

const num = (n) => {
    return (Math.round(n * 100) / 100)
}

const tableHeader = (text) => {
    return ({ text: text, style: styles.tableHeader })
}
const tableData = (text) => {
    return ({ text: text, style: styles.tableData })
}
const tableDataSmall = (text) => {
    return ({ text: text, style: styles.tableDataSmall })
}

const geoLink = (geo) => {
    let text = "-";
    let link = ""

    if (!geo) {
        return ({ text: text, style: text === "-" ? styles.geoLinkNone : styles.geoLink })

    }


    if (geo[0].length && geo[1].length) {
        text = "Map";
        link = `https://maps.google.com/?q=${geo[0]},${geo[1]}`;
    }
    return ({ text: text, style: text === "-" ? styles.geoLinkNone : styles.geoLink, link: link })
}


const StaffTable = (data, staffName, tf) => {
    return ({
        table: {
            headerRows: 1,
            widths: [260, 91.6, 91.6, 91.6],
            style: styles.table,
            dontBreakRows: true,
            body: [
                [
                    tableHeader("Staff member"),
                    tableHeader("Gross Hours"),
                    tableHeader("Lunch"),
                    tableHeader("Net Hours")
                ],
                [
                    tableData(staffName),
                    tableData(DisplayHoursWithFormat(num(data.total), tf)),
                    tableData(DisplayHoursWithFormat(num(data.lunch), tf)),
                    tableData(DisplayHoursWithFormat(num(data.total - data.lunch), tf))
                ]
            ]
        },
        layout: 'noBorders'
    })
}

const SiteTable = (data, siteName, tf) => {
    return ({
        table: {
            headerRows: 1,
            widths: [260, 91.6, 91.6, 91.6],
            style: styles.table,
            dontBreakRows: true,
            body: [
                [
                    tableHeader("Site name"),
                    tableHeader("Gross hours"),
                    tableHeader("Lunch"),
                    tableHeader("Net hours")
                ],
                [
                    tableData(siteName),
                    tableData(DisplayHoursWithFormat(num(data.total), tf)),
                    tableData(DisplayHoursWithFormat(num(data.lunch), tf)),
                    tableData(DisplayHoursWithFormat(num(data.total - data.lunch), tf))
                ]
            ]
        },
        layout: 'noBorders'
    })
}

const DetailTable = (data, tf) => {
    let records = [];
    // console.log(data)
    data.forEach(x => {
        records.push(DetailRow(x, tf))
    })
    return ({
        table: {
            headerRows: 1,
            borders: [true, false, false, false],
            dontBreakRows: true,
            // widths:[100,100,150,50,30,30],
            widths: [60, 60, 60, 60, 60, 60, 20, 20, 20, 30, 20],
            body: [
                [tableHeader("Start time"),
                    tableHeader("End time"),
                    tableHeader("Site"),
                    tableHeader("Notes"),
                    tableHeader("Staff Type"),
                    tableHeader("Task Type"),
                    tableHeader("Start Geo"),
                    tableHeader("End Geo"),
                    tableHeader("Gross Hours"),
                    tableHeader("Lunch"),
                    tableHeader("Net Hours")
                ],
                ...records
            ]
        },
        layout: 'noBorders'
    })
}

const DetailRow = (r, tf) => {

    return (
        [
            tableDataSmall(r.start),
            tableDataSmall(r.end),
            tableDataSmall(r.site),
            tableDataSmall(r.notes),
            tableDataSmall(r.staffType),
            tableDataSmall(r.taskType),
            geoLink(r.geoStart),
            geoLink(r.geoEnd),
            tableDataSmall(DisplayHoursWithFormat(num(r.hours), tf, true)),
            tableDataSmall(r.lunch.replace('minutes', 'mins')),
            tableDataSmall(DisplayHoursWithFormat(num(parseLunch(r.lunch, r.hours)), tf, true))
        ]

    )
}

const Spacer = () => {
    return ({
        table: {
            headerRows: 1,
            body: [
                [{ text: " ", style: styles.spacer }],
                [{ text: "", style: styles.spacer }]
            ],
        },
        layout: 'noBorders'
    })
}

const createStaffGroup = (data, x, tf) => {
    var sortedRecords = data[x].records.sort((x, y) => +new Date(x.start) - +new Date(y.start));

    let group = {
        stack: [
            StaffTable(data[x], x, tf),
            { canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1 }] },
            Spacer(),
            DetailTable(sortedRecords, tf),
            Spacer(),
            Spacer(),
            { text: "", pageBreak: 'after' }
        ],
        unbreakable: false
    }
    return group;
}


const createPDFStaff = async(data, range, view, tf) => {

    // console.log(range)
    const { vfs } = vfs_fonts.pdfMake;
    pdfMake.vfs = vfs;

    let content = [];

    content.push({ text: "Timesheet Report", style: styles.title })
    content.push({ text: `${range.start} to ${range.end}`, style: styles.range })

    content.push(Spacer())

    Object.keys(data).forEach((x, i) => {

        let group = createStaffGroup(data, x, tf);
        content.push(group)


    })

    let startString = moment(range.start, 'Do MMMM YYYY').format('DD/MM/YY')
    let endString = moment(range.end, 'Do MMMM YYYY').format('DD/MM/YY')
    const title = `Timesheets (${view}) ${startString} - ${endString}`;
    const def = {
        info: {
            title: title,
            author: 'SafetyWise',
            subject: `Timesheets ${startString} - ${endString}`
        },
        pageSize: "A4",
        pageOrientation: "portrait",
        content: content,
        footer: {
            columns: [
                { image: await getBase64ImageFromURL('logo2.png'), fit: [100, 100], alignment: 'center' }
            ]
        },
    }

    pdfMake.createPdf(def, null, fonts).download(title)

}

const getBase64ImageFromURL = (url) => {
    return new Promise((resolve, reject) => {
        var img = new Image();
        img.setAttribute("crossOrigin", "anonymous");

        img.onload = () => {
            var canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;

            var ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);

            var dataURL = canvas.toDataURL("image/png");

            resolve(dataURL);
        };

        img.onerror = error => {
            reject(error);
        };

        img.src = url;
    });
}

const createPDFSite = async(data, range, view, tf) => {
    // console.log(data)
    // console.log(range)
    const { vfs } = vfs_fonts.pdfMake;
    pdfMake.vfs = vfs;

    let content = [];

    content.push({ text: "Timesheet Report", style: styles.title })
    content.push({ text: `${range.start} to ${range.end}`, style: styles.range })

    content.push(Spacer())

    Object.keys(data).forEach((x, i) => {

        content.push(SiteTable(data[x], x, tf))
        Object.keys(data[x].staff).forEach(staffName => {

            content.push({ canvas: [{ type: 'line', x1: 0, y1: 5, x2: 595 - 2 * 40, y2: 5, lineWidth: 1 }] })

            content.push({
                    table: {
                        body: [
                            [
                                { text: " " },
                                createStaffGroup(data[x].staff, staffName, tf),
                                { text: " ", pageOrientation: 'portrait', pageBreak: 'after' },

                            ]
                        ]
                    },
                    layout: 'noBorders'
                }

            )
        })

        content.push(Spacer())
        content.push(Spacer())

    })


    let startString = moment(range.start, 'Do MMMM YYYY').format('DD/MM/YY')
    let endString = moment(range.end, 'Do MMMM YYYY').format('DD/MM/YY')
    const title = `Timesheets (${view}) ${startString} - ${endString}`;
    const def = {
        info: {
            title: title,
            author: 'SafetyWise',
            subject: `Timesheets ${startString} - ${endString}`
        },
        pageSize: "A4",
        pageOrientation: "portrait",
        content: content,
        footer: {
            columns: [
                { image: await getBase64ImageFromURL('logo2.png'), fit: [100, 100], alignment: 'center' }
            ]
        },
    }

    pdfMake.createPdf(def, null, fonts).download(title)
}

const createPDF = async(view, data, range, tf) => {

    console.log("FROM CREATEPDF")
    console.log(tf)

    if (view === "staff") {
        console.log(data)

        let pageSize = 1000;

        Object.keys(data).forEach(x => {
            if (data[x].records.length > pageSize) {
                let page_count = Math.ceil(data[x].records.length / pageSize);
                let seed = JSON.parse(JSON.stringify(data[x]))

                delete data[x]

                for (let _page = 0; _page < seed.records.length; _page += pageSize) {
                    let pageNo = _page / pageSize;
                    let newData = {...seed }
                    newData.records = seed.records.slice(_page, _page + pageSize);
                    data[`${x} page ${pageNo + 1}`] = newData;
                }
            }
        })

        await createPDFStaff(data, range, view, tf);
    } else {
        await createPDFSite(data, range, view, tf);
    }
}


export default createPDF;