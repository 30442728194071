import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";
import { useIsOnline } from "utils";

const useStyles = makeStyles((theme) => ({
	root: {
		position: "relative",
		bottom: 0,
	},
}));

const NUMBER_PER_PAGE = 5;

const PaginationRounded = ({
	onGetList,
	pagination,
	orderBy,
	ascending,
	searchKey,
	hidePagination,
	disabledOnLoadFetch, // use in Photo list only
	page, // an optional parameter that can be passed to sync current page with pagination
	// to disable onload fetch list when redirected to photo list on save, photo goes though a queue before it gets save
}) => {
	const classes = useStyles();
	const isOnline = useIsOnline();
	const queryPageSize = pagination.queryPageSize || NUMBER_PER_PAGE;
	const [isFirstLoad, setIsFirstLoad] = useState(true);

	const handlePageChange = (event, value) => {
		if (value) {
			onGetList({
				page: value,
				pageSize: hidePagination ? 5000 : queryPageSize,
				orderBy: orderBy || "createdAt",
				ascending: ascending !== undefined ? ascending : true,
				searchKey: searchKey || "",
			});
		}
	};

	useEffect(() => {
		if (!disabledOnLoadFetch) {
			onGetList({
				page: 1,
				pageSize: hidePagination ? 5000 : queryPageSize,
				orderBy: orderBy || "createdAt",
				ascending: ascending !== undefined ? ascending : true,
				searchKey: searchKey || "",
			});
		}
	}, [searchKey, orderBy, ascending]);

	useEffect(() => {
		if (pagination.refreshList === undefined) {
			return;
		}

		if (isFirstLoad) {
			setIsFirstLoad(false);
			return;
		}

		onGetList({
			page: pagination.pageNumber,
			pageSize: hidePagination ? 5000 : queryPageSize,
			orderBy: orderBy || "createdAt",
			ascending: ascending !== undefined ? ascending : true,
			searchKey: searchKey || "",
		});
	}, [pagination.refreshList]);

	if (!pagination || (pagination && pagination.totalNumberOfRecords === 0)) {
		return null;
	}

	const calculateNumberOfPages = () => {
		const listDataTotal = pagination.listData
			? pagination.listData.length
			: 1;
		return isOnline
			? pagination.totalNumberOfPages // online - use totalNumberOfPages from api directly
			: listDataTotal % queryPageSize > 0 // offline - calulate totalNumberOfPages from persisted listData
			? (listDataTotal - (listDataTotal % queryPageSize)) /
					queryPageSize +
			  1
			: listDataTotal % queryPageSize === 0
			? listDataTotal / queryPageSize
			: 1;
	};

	return (
		<div className={classes.root}>
			{!hidePagination && (
				<Pagination
					page={page || pagination.page}
					count={calculateNumberOfPages()}
					color="primary"
					onChange={handlePageChange}
				/>
			)}
		</div>
	);
};

PaginationRounded.propTypes = {
	pagination: PropTypes.object.isRequired,
	onGetList: PropTypes.func.isRequired,
	orderBy: PropTypes.string,
	ascending: PropTypes.bool,
	searchKey: PropTypes.string,
	hidePagination: PropTypes.bool,
	onHydatePagination: PropTypes.func,
	disabledOnLoadFetch: PropTypes.bool,
};

export default PaginationRounded;
