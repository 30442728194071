import React, { useContext } from "react";
import {
  detail,
  detail_alert,
  detail_hours,
  detail_map,
  detail_row,
  f2,
  f25,
  text_columns,
  geoLink,
  row_col_hours,
  row_col_time,
} from "./Detail.module.css";

import {  row_col_staff_type} from "./row.module.css";

import DisplayHours from "./DisplayHours";
import { parseLunch } from "./DisplayHours";

const GeoLink = (geo) => {
  // console.log("Making GEO")
  if (geo == "") {
    return <div className={detail}>-</div>;
  }
  // console.log(geo)
  if (!geo[0].length || !geo[1].length) {
    return <div className={detail}>-</div>;
  }
  // if(!geo){
  //   console.log(geo)
  // }
  return (
    <a
      className={`${detail} ${geoLink}`}
      href={`https://maps.google.com/?q=${geo[0]},${geo[1]}`}
      target={`_blank`}
    >
      Map
    </a>
  );
};

const Detail = (props) => {
  return (
    <div
      className={
        !props.record.ers.length
          ? `${detail_row}`
          : `${detail_row} ${detail_alert}`
      }
    >
      <div className={`${detail} ${row_col_time}`}>{props.record.start}</div>
      <div className={`${detail} ${row_col_time}`}>{props.record.end}</div>
      <div className={`${detail} ${text_columns}`}>{props.record.site || "-"}</div>
      <div className={`${detail} ${text_columns}`}>{props.record.notes || "-"}</div>
      
      {props.showStaffType && (
        <div className={`${detail} ${text_columns}`}>{props.record.staffType || "-"}</div>
      )}
      {props.showTaskType && (
        <div className={`${detail} ${text_columns}`}>{props.record.taskType || "-"}</div>
      )}
      {props.showGeolocation && (
        <div className={`${detail} ${detail_map}`}>
          {GeoLink(props.record.geoStart)}
        </div>
      )}
      {props.showGeolocation && (
        <div className={`${detail} ${detail_map}`}>
          {GeoLink(props.record.geoEnd)}
        </div>
      )}

      <div className={`${detail} ${detail_hours}`}>
        <DisplayHours hours={Math.round(props.record.hours * 100) / 100} />
      </div>
      <div className={`${detail} ${detail_hours}`}>{props.record.lunch}</div>
      <div className={`${detail} ${detail_hours}`}>
        {/* <DisplayHours hours={}/> */}
        <DisplayHours
          hours={parseLunch(
            props.record.lunch,
            Math.round(props.record.hours * 100) / 100
          )}
        />
      </div>
    </div>
  );
};

export default Detail;

// {props.record.ers.length?`${detail}` : `${detail} ${detail_alert}`}
