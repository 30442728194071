import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { DatePicker } from "@material-ui/pickers";
import { isStaffUser, useRouter, sortByPropertyAlphabetically } from "utils";
import * as fromRoutes from "../actions/types";
import { Grid, Typography, Checkbox } from "@material-ui/core";
import {
  Feedback,
  FormActions,
  FormButton,
  FormSubmitButton,
  ScrollToTopOnMount,
  SelectInput,
} from "frame/components";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 3, 10, 3),
    backgroundColor: theme.palette.white,
  },
  body: {
    margin: theme.spacing(0, 0, 2, 0),
    maxWidth: 540,
    "& > *": {
      paddingLeft: "0 !important",
      // paddingRight: '0 !important',
    },
  },
  message: {
    marginBottom: theme.spacing(2),
  },
}));

const Reports = ({
  save,
  onDownload,
  onClearResponseMessage,
  responseMessage,
  staffList,
  siteList,
  role,
  onOpenTimesheetReport,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

  const [values, setValues] = useState({});
  const [allRecords, setAllRecords] = useState(true);
  // const [showErrorMessage, setShowErrorMessage] = useState(false)

  // to display response message for 3 seconds only
  // useEffect(() => {
  //   if(responseMessage) {
  //     setShowErrorMessage(true)
  //     setTimeout(() => {
  //       setShowErrorMessage(false)
  //     }, 3000)
  //   }
  // }, [responseMessage])

  useEffect(() => {
    onClearResponseMessage();
  }, [onClearResponseMessage]);

  const { saving } = save;
  const { history } = useRouter();

  if (isStaffUser(role)) {
    history.push(fromRoutes.ACCOUNT_PROFILE_PATH);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!saving) {
      onDownload(values);
    }
  };

  const onReset = () => setValues({});

  const onSelectOption = (key) => (value) => {
    if (key === "report") {
      //clear any error message on report dropdown toggle
      onClearResponseMessage();
    }

    setValues({
      ...values,
      [key]: value,
    });
  };

  const onSelectDate = (key) => (value) =>
    setValues({
      ...values,
      [key]: value.startOf("day").format(),
    });

  const options = [
    {
      label: "Hazards",
      value: "hazards",
    },
    {
      label: "Hazardous Substances",
      value: "hazardous_substances",
    },
    {
      label: "Staff Activity",
      value: "staff",
    },
    {
      label: "Staff Training",
      value: "staff_training",
    },
    {
      label: "Incidents",
      value: "accidents",
    },
    {
      label: "Timesheets",
      value: "timesheets",
    },
    {
      label: "Safety Minutes",
      value: "safety_minutes",
    },
    {
      label: "Induction Meetings",
      value: "inductions",
    },
  ].sort(sortByPropertyAlphabetically("label"));

  let staffOptions = staffList
    .map((s) => ({ label: s.fullName, value: s.id }))
    .sort(sortByPropertyAlphabetically("label"));

  let siteOptions = siteList
    .map((s) => ({ label: s.siteName, value: s.id }))
    .concat([{ label: "All", value: "all" }])
    .sort(sortByPropertyAlphabetically("label"));

  const IsHazardOrSubstance =
    values.report === "hazards" || values.report === "hazardous_substances";

  return (
    <div className={classes.root}>
      <ScrollToTopOnMount />
      <form autoComplete="off" noValidate onSubmit={onSubmit}>
        {!mobileView && <Typography variant="h2">Reports</Typography>}

        <Grid className={classes.body} container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.message} variant="body1">
              Download your data as a csv file. You can format it in Excel as
              you wish.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
              id={"report_type"}
              disabled={saving}
              label="Report Type"
              options={options}
              value={values.report}
              onChange={onSelectOption("report")}
            />
          </Grid>

          {values.report === "staff" && (
            <Grid item xs={12}>
              <SelectInput
                id={"staff_id"}
                disabled={saving}
                label="Staff"
                options={staffOptions}
                value={values.staffId}
                onChange={onSelectOption("staffId")}
              />
            </Grid>
          )}

          {IsHazardOrSubstance && (
            <Grid item xs={12}>
              <SelectInput
                noNone
                id={"site_id"}
                disabled={saving}
                label="Site"
                options={siteOptions}
                value={values.siteId || "all"}
                onChange={onSelectOption("siteId")}
              />
            </Grid>
          )}
          {!IsHazardOrSubstance && (
            <>
              <Grid item xs={12}>
                <Checkbox
                  checked={allRecords}
                  value={allRecords}
                  inputProps={{
                    "aria-label": "primary checkbox",
                  }}
                  onChange={() => setAllRecords(!allRecords)}
                />
                <span>All records</span>
              </Grid>
              <Grid item md={6} xs={12}>
                <DatePicker
                  disabled={saving || allRecords}
                  format="Do MMMM YYYY"
                  fullWidth
                  inputVariant="outlined"
                  label="From Date"
                  maxDate={values.to}
                  value={values.from || null}
                  variant="outlined"
                  disableFuture={true}
                  onChange={onSelectDate("from")}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DatePicker
                  disabled={saving || allRecords}
                  format="Do MMMM YYYY"
                  fullWidth
                  inputVariant="outlined"
                  label="To Date"
                  minDate={values.from}
                  value={values.to || null}
                  variant="outlined"
                  disableFuture={true}
                  onChange={onSelectDate("to")}
                />
              </Grid>
            </>
          )}
        </Grid>
        <FormActions>
          <FormSubmitButton
            loading={saving}
            disabled={
              !values.report || (values.report === "staff" && !values.staffId)
            }
          >
            Download
          </FormSubmitButton>
          <FormButton disabled={saving} onClick={onReset}>
            Reset
          </FormButton>
          <FormButton
            disabled={saving || values.report !== "timesheets"}
            onClick={() => onOpenTimesheetReport(values)}
          >
            Open Timesheet Report
          </FormButton>
        </FormActions>
        {responseMessage !== "" && (
          <Feedback
            disableTimeout
            active
            color={
              responseMessage.toLowerCase().includes("success")
                ? "success"
                : "error"
            }
          >
            {responseMessage === "Invalid model" ||
            responseMessage.includes("Object reference")
              ? "Error occurred. Please try again or contact support."
              : responseMessage}
          </Feedback>
        )}
      </form>
    </div>
  );
};

Reports.propTypes = {
  reports: PropTypes.object,
  siteList: PropTypes.array.isRequired,
  staffList: PropTypes.array.isRequired,
  save: PropTypes.exact({
    done: PropTypes.bool,
    error: PropTypes.string,
    saving: PropTypes.bool,
    success: PropTypes.bool,
  }),
  onDownload: PropTypes.func.isRequired,
  onClearResponseMessage: PropTypes.func.isRequired,
  responseMessage: PropTypes.string,
  role: PropTypes.string.isRequired,
  onOpenTimesheetReport: PropTypes.func.isRequired,
};

export default Reports;
