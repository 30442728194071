import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import FeatureHeader from "frame/components/feature-header";
import { ScrollToTopOnMount } from "frame/components";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera"; // Photos
import AccessAlarmIcon from "@material-ui/icons/AccessAlarm"; // Timesheets
import ForumIcon from "@material-ui/icons/Forum"; // Safety Minutes
import FlashOnIcon from "@material-ui/icons/FlashOn"; // Incident Records
import PersonAddIcon from "@material-ui/icons/PersonAdd"; // Induction Meetings
import PeopleIcon from "@material-ui/icons/People"; // Customers
import { LIST_TIMESHEETS_ROUTE } from "features/timesheet/timesheet-routes";
import { JOBS_PATH } from "features/jobs";
import * as fromHealthSafetyForms from "features/health-safety-forms";
import { TASK_INCIDENTS_ROUTE } from "features/health-and-safety";
import HomeCard from "./home-card";
import { connect } from "react-redux";
import {
	onClearSearch,
	onSafetyMinutesListRequest,
	onIncidentListRequest,
	onInductionMinutesListRequest,
	onStaffTrainingListRequest
} from "features/health-and-safety/actions";
import { onGetTimesheetCount } from "../home-actions";
import { Page } from "frame/components";
import { safetyWiseApi } from "utils/safetyWiseApi";
import {
	TASK_INDUCTION_MINUTES_ROUTE,
	TASK_SAFETY_MINUTES_ROUTE,
	TASK_STAFF_TRAINING_ROUTE
} from "features/health-and-safety/actions/types";

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(3)
	},
	header: {
		[theme.breakpoints.down("xs")]: {
			marginTop: theme.spacing(-3),
			marginBottom: theme.spacing(-3)
		}
	}
}));

const mapStateToProps = ({ profile, jobs, healthSafety, home, organisations }) => {
	return {
		role: profile.role,
		numberOfRecords: {
			jobs: jobs.pagination?.totalNumberOfRecords,
			safetyMinutes: healthSafety.safetyMinutes.totalNumberOfRecords,
			incidents: healthSafety.incidents.totalNumberOfRecords,
			inductionMeetings: healthSafety.inductions.totalNumberOfRecords,
			staffTrainings: healthSafety.staffTrainings.totalNumberOfRecords,
		},
		timesheetCount: home.timesheetCount,
		currentOrganisationGUID: organisations.currentOrganisation
	};
};

const mapDispatchToProps = {
	onClearSearchProp: onClearSearch,
	onGetIncidentList: onIncidentListRequest,
	onGetTimesheetCountProp: onGetTimesheetCount
};

export const TasksHome = connect(
	mapStateToProps,
	mapDispatchToProps
)(
	({
		numberOfRecords,
		onClearSearchProp,
		onGetIncidentList,
		onGetTimesheetCountProp,
		timesheetCount,
		currentOrganisationGUID
	}) => {
		const classes = useStyles();
		const [taskItemsCount, setTaskItemsCount] = useState({
			timesheet: "0", // Todo: The count being returned is incorrect
			safetyMinutes: numberOfRecords.safetyMinutes || "0",
			incidents: "0", // Todo: The count being returned is incorrect
			inductionMeetings: numberOfRecords.inductionMeetings || "0",
			staffTrainings: numberOfRecords.staffTrainings || "0",
			jobs: numberOfRecords.jobs || "0"
		});
		const [countIsLoaded, setCountIsLoaded] = useState(false);

		useEffect(() => {
			onClearSearchProp();
			onGetIncidentList();
			onGetTimesheetCountProp();

			safetyWiseApi
				.get(`${currentOrganisationGUID}/organisation/get-tasks-count`)
				.then((result) => {
					setTaskItemsCount(result.data);
					setCountIsLoaded(true);
				});
		}, []);

		const links = [
			{
				title: "Timesheets",
				href: LIST_TIMESHEETS_ROUTE,
				icon: AccessAlarmIcon,
				number: timesheetCount || 0 // To do: the count taskItemsCount.timesheet is incorrect
			},
			{
				title: "Photos or Notes",
				href: JOBS_PATH,
				icon: PhotoCameraIcon,
				number: taskItemsCount.jobs
			},
			{
				title: "Incidents",
				href: TASK_INCIDENTS_ROUTE,
				icon: FlashOnIcon,
				number: numberOfRecords.incidents || 0 // To do: the count taskItemsCount.incidentRecords is incorrect
			},
			{
				title: "Induction Meetings",
				href: TASK_INDUCTION_MINUTES_ROUTE,
				icon: PersonAddIcon,
				number: taskItemsCount.inductionMeetings
			},
			{
				title: "Safety Minutes",
				href: TASK_SAFETY_MINUTES_ROUTE,
				icon: ForumIcon,
				number: taskItemsCount.safetyMinutes
			},
			{
				title: "Staff Trainings",
				href: TASK_STAFF_TRAINING_ROUTE,
				icon: PeopleIcon,
				number: taskItemsCount.staffTrainings
			}
		];

		return (
			<Page className={classes.root} title="Tasks">
				<ScrollToTopOnMount />
				<FeatureHeader title="Tasks" className={classes.header} />

				<Grid container>
					<Grid item lg={6} md={12} sm={12} xs={12}>
						{numberOfRecords &&
							links.map(({ icon, href, title, number }, index) => (
								<HomeCard
									key={`task-home-card-${index}`}
									text={title}
									icon={icon}
									link={href}
									number={number}
								/>
							))}
					</Grid>
				</Grid>
			</Page>
		);
	}
);
