import React, { useState } from "react";
// import PropTypes from 'prop-types'
import { makeStyles, useTheme } from "@material-ui/styles";
import { Badge } from "@material-ui/core";
import { Tab, Tabs, SelectInput } from "frame/components";
import { useRouter, isNotNullOrEmpty } from "utils";
import * as fromRoutes from "../actions/types";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
	root: {},
	dropdownLabel: {
		minWidth: 180,
	},
	dropdown: {
		marginTop: theme.spacing(7),
	},
	expandIcon: {
		marginBottom: -10,
		height: 30,
		width: 30,
	},
	badge: {
		position: "absolute",
		right: "-5px",
	},
	mobileMenu: {
		padding: theme.spacing(2),
	},
}));

const tabConfig = [
	{
		label: "Summary",
		path: fromRoutes.STAFF_MEMBER_SUMMARY_PATH,
	},
	{
		label: "Photo or Note",
		path: fromRoutes.STAFF_MEMBER_NOTES_PATH,
	},
	{
		label: "Training",
		path: fromRoutes.STAFF_MEMBER_TRAINING_PATH,
	},
];

const MenuTabs = () => {
	const theme = useTheme();
	const classes = useStyles();
	const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

	const { history, match } = useRouter();
	const { staffId, staffTab } = match.params;

	const [activeTab, setActiveTab] = useState(
		fromRoutes.STAFF_MEMBER_SUMMARY_PATH
	);

	const quickPicList = useSelector(({ jobs }) => jobs.pagination?.results);
	const staffTrainingList = useSelector(
		({ healthSafety }) => healthSafety.staffTrainings?.results
	);

	const staffFilteredQuickPics = quickPicList
		? quickPicList.filter((s) => s.staffId === staffId)
		: [];

	const staffFilteredStaffTrainingList = staffTrainingList
		? staffTrainingList.filter((training) =>
				JSON.stringify(training.people).includes(staffId)
		  )
		: [];

	const getTabPath = (path) => `${fromRoutes.STAFF_PATH}/${staffId}/${path}`;

	const onTabChange = (event, value) => history.push(getTabPath(value)); // todo: dispatch push()

	const quickPicNum =
		isNotNullOrEmpty(staffFilteredQuickPics) &&
		staffFilteredQuickPics.length;
	const trainingNum =
		isNotNullOrEmpty(staffFilteredStaffTrainingList) &&
		staffFilteredStaffTrainingList.length;

	const mobileOptions = tabConfig.map((tab) => ({
		label: tab.label,
		value: tab.path,
	}));

	const onChangeMobileTab = (value) => {
		history.push(getTabPath(value)); // todo: dispatch push()
		setActiveTab(value);
	};

	return (
		<div className={classes.root}>
			{mobileView ? (
				<div className={classes.mobileMenu}>
					<SelectInput
						noNone
						id="staff_mobile_menu"
						options={mobileOptions}
						value={activeTab}
						onChange={onChangeMobileTab}
					/>
				</div>
			) : (
				<Tabs
					onChange={onTabChange}
					scrollButtons="auto"
					value={staffTab || "summary"}
					variant="scrollable"
				>
					<Tab
						label="Summary"
						value={fromRoutes.STAFF_MEMBER_SUMMARY_PATH}
					/>
					<Tab
						label={
							<>
								Photo or Note
								{quickPicNum && (
									<Badge
										className={classes.badge}
										badgeContent={quickPicNum}
										color="primary"
									/>
								)}
							</>
						}
						value={fromRoutes.STAFF_MEMBER_NOTES_PATH}
					/>
					<Tab
						label={
							<>
								Training
								{trainingNum && (
									<Badge
										className={classes.badge}
										badgeContent={trainingNum}
										color="primary"
									/>
								)}
							</>
						}
						value={fromRoutes.STAFF_MEMBER_TRAINING_PATH}
					/>
				</Tabs>
			)}
		</div>
	);
};

// MenuTabs.propTypes = {}

export default MenuTabs;
