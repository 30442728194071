import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/styles";
import { ValidatorForm } from "react-material-ui-form-validator";
import {
    Modal,
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    CircularProgress,
} from "@material-ui/core";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import clsx from "clsx";
import DynamicTable from "./dynamic-table";
import { FormSubmitButton } from "frame/components";
import { onBatchAdd, onList } from "../actions";
import uuid from "uuid";
import { isValidEmail, isValid, isValidRole } from "utils";

const useStyles = makeStyles((theme) => ({
    root: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        outline: "none",
        boxShadow: theme.shadows[20],
        width: "auto",
        maxHeight: "100%",
        overflowY: "auto",
        maxWidth: "100%",
    },
    container: {
        marginTop: theme.spacing(2),
    },
    center: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        fontWeight: 600,
    },
    output: {
        maxHeight: 500,
        overflowY: "auto",
    },
    primaryButton: {
        background: theme.palette.highlight,
        borderColor: theme.palette.highlight,
        "&:hover": {
            background: "#c1c72c",
            borderColor: "#c1c72c",
        },
    },
    errorText: {
        color: theme.palette.error.main,
    },
}));

const isValidStaffData = (data) => {
    return (
        isValid(data.firstName) &&
        isValid(data.lastName) &&
        isValidEmail(data.email) &&
        isValidRole(data.organisationRole) &&
        isValid(data.status)
    );
};

const StaffBatchCreate = ({ onClose, onBatchAdd, onRefetch, flags }) => {
    const classes = useStyles();
    const xlsxUploaderRef = useRef();

    const [templateData, setTemplateData] = useState(null);

    // flags
    const [isLoading, setIsLoading] = useState(false);
    const [submitErrored, setSubmitErrored] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [processingCounter, setProcessingCounter] = useState(0);

    const onUpload = (event) => {
        setIsLoading(true);
        const reader = new FileReader();
        reader.readAsBinaryString(event.target.files[0]);

        reader.onloadend = () => {
            // Parse data
            const workBook = XLSX.read(reader.result, {
                type: "binary",
                bookVBA: true,
            });

            // Get first worksheet
            const workSheetName = workBook.SheetNames[0];
            const workSheet = workBook.Sheets[workSheetName];
            // Convert array of arrays
            const data = XLSX.utils.sheet_to_json(workSheet);
            // Update state
            setTemplateData(
                data.slice(0, 50).map((item) => ({
                    // take 50 max records
                    id: uuid.v4(),
                    ...item,
                    valid: isValidStaffData(item) ? "Yes" : "No",
                }))
            );
            setIsLoading(false);
        };
    };

    const onConfirmBatchCreate = () => {
        setIsSubmitting(true);
        let counter = processingCounter;

        // filter invalid data
        const filteredData = templateData.filter((i) => i.valid === "Yes");

        if (filteredData && filteredData.length > 0) {
            filteredData.forEach((record, index) => {
                setTimeout(() => {
                    // create staff in the interval of 3 secs
                    onBatchAdd(record, index);
                    counter++;
                    setProcessingCounter(counter);
                }, 3000);
            });
        } else {
            setSubmitErrored(true);
        }
    };

    useEffect(() => {
        if (
            templateData &&
            processingCounter ===
            templateData.filter((i) => i.valid === "Yes").length
        ) {
            setIsSubmitting(false);
            setIsDone(true);
        } // eslint-disable-next-line
    }, [processingCounter]);

    const onDoneClose = () => {
        onRefetch();
        onClose();
    };

    const isProcessing = flags && JSON.stringify(flags).includes("processing");
    const isErrored =
        templateData &&
        templateData.filter((i) => i.valid === "Yes").length === 0;

    return ( <
        Modal size = "lg"
        open = { true }
        onClose = { isSubmitting ? null : onClose } >
        <
        Card className = { classes.root } >
        <
        ValidatorForm autoComplete = "off"
        noValidate onSubmit = {
            () => {}
        } >
        <
        CardContent >
        <
        Typography align = "center"
        gutterBottom variant = "h3" > {!templateData ?
            "Batch Create Staff" : "Review Import Data"
        } <
        /Typography>

        <
        Grid className = { classes.container }
        container spacing = { 3 } > { /* Step 1 */ } {
            !templateData && ( <
                >
                <
                Grid item xs = { 12 } >
                <
                Typography align = "center"
                variant = "body2" >
                Import excel template to batch create staff users.(Max 50 records at time) <
                /Typography> < /
                Grid > <
                Grid item xs = { 12 } >
                <
                input id = "xlsxUploader"
                type = "file"
                accept = ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange = { onUpload }
                ref = { xlsxUploaderRef }
                style = {
                    { display: "none" }
                }
                /> <
                Button variant = "contained"
                className = {
                    clsx(
                        classes.center,
                        classes.primaryButton
                    )
                }
                onClick = {
                    () =>
                    xlsxUploaderRef.current.click()
                } >
                Upload Template <
                /Button> < /
                Grid > <
                />
            )
        }

        { /* Step 2 */ } <
        Grid item xs = { 12 }
        className = { classes.output } > {!isLoading && templateData && ( <
                DynamicTable list = { templateData }
                flags = { flags }
                />
            )
        }

        {
            (submitErrored || isErrored) && ( <
                Typography variant = "body1"
                className = { classes.errorText } >
                Invalid users
                import template file.Please
                try again or contact support. <
                /Typography>
            )
        }

        {
            isLoading && ( <
                div className = { classes.center } >
                <
                CircularProgress / >
                <
                /div>
            )
        } <
        /Grid>

        {
            templateData && ( <
                Grid item xs = { 12 } >
                <
                FormSubmitButton disabled = { isSubmitting || isProcessing }
                loading = { isSubmitting || isProcessing }
                // variant="contained"
                className = { classes.center }
                // className={clsx(classes.center, classes.primaryButton)}
                onClick = {
                    submitErrored ||
                    isErrored ||
                    (isDone &&
                        !isSubmitting &&
                        !isProcessing) ?
                    onDoneClose : onConfirmBatchCreate
                } > {
                    submitErrored ||
                    isErrored ||
                    (isDone &&
                        !isSubmitting &&
                        !isProcessing) ?
                    `Close` : `Confirm Batch Create Staff (${
													templateData.filter(
														(i) => i.valid === "Yes"
													).length
											  })`
                } <
                /FormSubmitButton> < /
                Grid >
            )
        } <
        /Grid> < /
        CardContent > <
        /ValidatorForm> < /
        Card > <
        /Modal>
    );
};

StaffBatchCreate.propTypes = {
    onClose: PropTypes.func.isRequired,
    onBatchAdd: PropTypes.func.isRequired,
    onRefetch: PropTypes.func.isRequired,
    flags: PropTypes.array.isRequired,
};

const mapStateToProps = ({ staff }) => ({
    flags: staff.batchCreateFlags,
});

const mapDispatchToProps = {
    onBatchAdd,
    onRefetch: onList,
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffBatchCreate);