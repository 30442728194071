import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "../reducers/root-reducer";
import { createEpicMiddleware } from "redux-observable";
import reduxReset from "redux-reset";

export const history = createBrowserHistory();

export const epicMiddleware = createEpicMiddleware();

// Middleware to inject history into actions
const injectHistoryMiddleware = store => next => action => {
  action.history = history;
  return next(action);
};

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = createRootReducer(history);

export default function configureStore(preloadedState) {
	const store = createStore(
		rootReducer,
		preloadedState,
		composeEnhancers(
			applyMiddleware(
				routerMiddleware(history),
				epicMiddleware,
				injectHistoryMiddleware // Inject history into actions
			),
			reduxReset()
		)
	);

	if (process.env.NODE_ENV !== "production") {
		if (module.hot) {
			module.hot.accept("../reducers/root-reducer", () => {
				store.replaceReducer(rootReducer);
			});
		}
	}

	return store;
}
