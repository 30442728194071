import * as R from "ramda";
import initialJobsState from "../models/job-state";
import Types from "../actions/types";
import queueTypes from "features/queue/actions/types";
import JobModel from "../models/job-model";
import uuid from "uuid";
// import { DBService } from 'frame/indexedDBService'
import ReactGA from "react-ga";
import {
	resetFlags,
	updateFlags,
	defaultFlagState,
	hasValue,
	sortByPropertyDate,
	defaultPaginationState,
	isProd,
	getCurrentTimestamp
} from "utils";

export const initialJobState = {
	...defaultPaginationState,
	refreshList: false,
	pageSize: 5
};

export const defaultState = {
	id: "",
	title: "",
	createdAt: "",
	type: "",
	customerId: "",
	siteId: "",
	staffId: "",
	notes: "",
	attachments: "",
	latitude: "",
	longitude: "",
	timestamp: getCurrentTimestamp(),
	organisationId: ""
};


const NUMBER_PER_PAGE = 5;

const setIsValid = (job) => {
	const validOptions =
		hasValue(job.customerId) ||
		hasValue(job.siteId) ||
		hasValue(job.staffId);

	job.isValid = hasValue(job.title) && hasValue(job.type) && validOptions;
};

const queueSendSucceeded = (state, action) => {
	const isNew = state.isNew;
	state.currentJob = R.clone(JobModel);
	isProd &&
		ReactGA.event({
			category: "Tasks",
			action: "Save Clicked",
			label: "Add photo or note",
		});

	// update results
	const resultsIndex = R.findIndex(R.propEq("id", action.payload.entityId))(
		state.pagination?.results
	);

	if (resultsIndex > -1) {
		state.pagination.results[resultsIndex].syncing = false;
	}
	// update listData
	const listDataIndex = R.findIndex(R.propEq("id", action.payload.entityId))(
		state.pagination?.listData
	);

	if (listDataIndex > -1) {
		state.pagination.listData[listDataIndex].syncing = false;
	}
	// remove attachments for succeeded jobs
	// const db = new DBService()
	// for(let i in state.jobList[index].attachments) {
	//   db.delete('attachments', state.jobList[index].attachments[i].id)
	// }

	// applySearch(state)
	return updateFlags(state, isNew ? { create: "done" } : { update: "done" });
};

const addClicked = (state, action) => {
	state.isAddModalShowing = true;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentJob = R.clone(JobModel);
	state.currentJob.id = uuid.v4();
	return resetFlags(state, defaultFlagState);
};

const editClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isNew = false;
	state.isEditModalShowing = true;
	state.currentJob = R.clone(
		R.find(R.propEq("id", action.payload), state.pagination?.results)
	);
	return state;
};

const saveClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.currentJob.id = state.isNew ? uuid.v4() : state.currentJob.id;

	let listData = state.pagination.listData || [];
	let results = state.pagination.results || [];

	// update state optimistically, let api do make request asyc in the background and overwrite state when done
	if (state.isNew) {
		// create new Photo

		// update List data
		listData.push(state.currentJob);
		state.pagination.listData = listData.sort(
			sortByPropertyDate("createdAt")
		);

		// update paged data
		results.length >= NUMBER_PER_PAGE && results.pop();
		results = results.reverse();
		results.push(state.currentJob);
		results = results.reverse();
		state.pagination.results = results;

		// update pagination state
		// calculate totalNumberOfPages again from listData size
		const totalNumberOfPages =
			listData.length % NUMBER_PER_PAGE > 0
				? (listData.length - (listData.length % NUMBER_PER_PAGE)) /
						NUMBER_PER_PAGE +
				  1
				: listData.length % NUMBER_PER_PAGE === 0
				? listData.length / NUMBER_PER_PAGE
				: 1;

		state.pagination.totalNumberOfRecords =
			state.pagination.totalNumberOfRecords + 1;
		state.pagination.totalNumberOfPages = totalNumberOfPages;
	} else {
		// edit Photo

		// update List data
		const listDataIndex = R.findIndex(R.propEq("id", state.currentJob.id))(
			listData
		);
		state.pagination.listData[listDataIndex] = state.currentJob;
		// update paged data
		const resultsIndex = R.findIndex(R.propEq("id", state.currentJob.id))(
			results
		);
		state.pagination.results[resultsIndex] = state.currentJob;
	}

	return resetFlags(
		state,
		state.isEditModalShowing
			? { update: "processing" }
			: { create: "processing" }
	);
};

const saveQueued = (state, action) => {
	// if (state.isNew) {
	//   state.pagination.results.push(state.currentJob)
	//   state.pagination.results = state.pagination.results.sort(sortByPropertyDate('createdAt'))
	// } else {
	//   const jobList = state.pagination.results
	//   const index = R.findIndex(R.propEq('id', state.currentJob.id))(jobList)
	//   state.pagination.results[index] = state.currentJob
	// }
	//applySearch(state)
	//state.currentJob = JobModel
	//state.isNew = true
	state.currentJob = JobModel;
	state.searchFilter = "";
	return state;
};

const addSucceeded = (state, action) => {
	return state;
};

const closeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isNew = true;
	state.isEditModalShowing = false;
	state.currentJob = R.clone(JobModel);
	state.currentJob.id = uuid.v4();
	return state;
};

const jobTitleChanged = (state, action) => {
	state.currentJob.title = action.payload;
	setIsValid(state.currentJob);
	return state;
};

const jobDateChanged = (state, action) => {
	state.currentJob.createdAt = action.payload;
	setIsValid(state.currentJob);
	return state;
};

const jobTypeChanged = (state, action) => {
	return {
		...state,
		currentJob: {
			...state.currentJob,
			type: action.payload,
			customerId: "",
			siteId: "",
			staffId: "",
		},
	};
};

const jobCustomerIdChanged = (state, action) => {
	state.currentJob.customerId = action.payload;
	setIsValid(state.currentJob);
	return state;
};

const jobSiteIdChanged = (state, action) => {
	state.currentJob.siteId = action.payload;
	setIsValid(state.currentJob);
	return state;
};

const jobStaffIdChanged = (state, action) => {
	state.currentJob.staffId = action.payload;
	setIsValid(state.currentJob);
	return state;
};

const jobNotesChanged = (state, action) => {
	state.currentJob.notes = action.payload;
	return state;
};

const fileChanged = (state, action) => {
	state.currentJob.attachments = action.payload.attachments;
	return state;
};

const getJobList = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	return updateFlags(state, { fetch: "processing" });
};

// const jobListReceived = (state, action) => {
//   state.pagination =  action.payload
// state.jobList = []
// if (action.payload) {
//   action.payload.map(function(item) {
//     state.jobList.push({
//       id: item.id,
//       syncing: false,
//       title: item.title,
//       createdAt: item.createdAt,
//       type: item.type,
//       customerId: item.customerId,
//       siteId: item.siteId,
//       staffId: item.staffId,
//       notes: item.notes,
//       attachments: item.attachments,
//       syncedAt: item.syncedAt,
//       geoLocation: {
//         latitude: item.latitude,
//         longitude: item.longitude,
//         timestamp: item.timestamp
//       },
//       creatorId: item.creatorId
//     })
//     return item
//   })
// }
// applySearch(state)
//   return state
// }

const jobListReceived = (state, { payload }) => {
	let pagination = defaultPaginationState;
	let listData =
		state.pagination && state.pagination.listData
			? state.pagination.listData
			: [];

	if (payload && payload.results && payload.results.length > 0) {
		pagination = payload;

		if (listData && listData.length > 0) {
			// appends payload to list data if not exists

			payload.results.forEach((newRecord) => {
				// check if data exists in the list already
				const existingDataIndex = listData.findIndex(
					(item) => item.id === newRecord.id
				);

				// replace existing entries (same id) with new data
				if (existingDataIndex === -1) {
					listData.push(newRecord);
				} else {
					listData[existingDataIndex] = newRecord;
				}
			});
		} else {
			// listData is empty, set results directly
			listData = payload.results;
		}
	}

	state.pagination = {
		...pagination,
		listData: listData.sort(sortByPropertyDate("createdAt")),
	};
	return updateFlags(state, { fetch: "done" });
};

// generic function to handle offline mode pagination
const hydrateList = (state, { payload }) => {
	const stateData = state.pagination;
	const listData = stateData.listData || [];
	const currentPagedData = stateData.results || [];

	const pageSize =
		stateData.pageNumber === stateData.totalNumberOfRecords
			? stateData.pageSize
			: NUMBER_PER_PAGE;

	// eslint-disable-next-line
	const totalNumberOfPages =
		listData.length % NUMBER_PER_PAGE > 0
			? (listData.length - (listData.length % NUMBER_PER_PAGE)) /
					NUMBER_PER_PAGE +
			  1
			: listData.length % NUMBER_PER_PAGE === 0
			? listData.length / NUMBER_PER_PAGE
			: 1;
	// eslint-disable-next-line
	let hydrateData = [];

	// check if listData has more data than currently displayed list
	if (payload && listData.length > currentPagedData.length) {
		let itemsToSkip = (payload.page - 1) * pageSize;
		let itemsToTake = pageSize;

		// work out paginated page to show from listData
		hydrateData = stateData.listData
			.slice(itemsToSkip)
			.slice(0, itemsToTake);
	}

	// state.pagination = {
	//   ...stateData,
	//   pageNumber: (payload && payload.page) || stateData.page || 1,
	//   totalNumberOfPages: totalNumberOfPages,
	//   results: hydrateData.length > 0 ? hydrateData : stateData.results,
	//   flag: 'error',
	//   error: payload || 'error occurred'
	// }
	return state;
};

const jobListFailed = (state, { payload }) => {
	return updateFlags(state, { fetch: "error" });
};

const removeClicked = (state, action) => {
	state.isAddModalShowing = false;
	state.isEditModalShowing = false;
	state.currentJob = R.find(
		R.propEq("id", action.payload),
		state.pagination?.results
	);
	return resetFlags(state, { delete: "processing" });
};

const jobRemoveSucceeded = (state, action) => {
	// state.jobList = state.jobList.filter(c => c.id !== state.currentJob.id)
	// applySearch(state)
	state.currentJob = JobModel;
	return updateFlags(state, { delete: "done" });
};
/*
const applySearch = state => {
  if (!state.searchFilter || state.searchFilter === '') {
    state.filteredJobList = state.jobList
  } else {
    // const matchTerm = term => item => {
    //   let itemWithoutIds = R.clone(item)
    //   itemWithoutIds.id = undefined
    //   itemWithoutIds.customerId = undefined
    //   itemWithoutIds.siteId = undefined
    //   let itemStr = JSON.stringify(itemWithoutIds)
    //   return itemStr.includes(term.toLowerCase())
    // }

    // state.filteredJobList = R.filter(
    //   matchTerm(state.searchFilter),
    //   state.jobList
    // )
    
    let list = state.jobList
    list.id = undefined
    list.customerId = undefined
    list.siteId = undefined
    let term = state.searchFilter
    state.filteredJobList = list.filter(item => JSON.stringify(item).toLowerCase().includes(term.toLowerCase()))
  }
}
*/

const searchChanged = (state, action) => {
	state.searchFilter = action.payload;
	// applySearch(state)
	return state;
};

const searchClear = (state, action) => {
	state.searchFilter = "";
	return state;
};

const globalMessageChanged = (state, action) => {
	state.globalMessage = action.payload;
	return state;
};

export const jobsReducer = (state = initialJobsState, action) => {
	switch (action.type) {
		case queueTypes.QUEUE_SEND_SUCCEEDED:
			return queueSendSucceeded(R.clone(state), action);
		case Types.JOBS_ADD_CLICKED:
			return addClicked(R.clone(state), action);
		case Types.JOBS_EDIT_CLICKED:
			return editClicked(R.clone(state), action);

		// save Photo (add/edit)
		case Types.JOBS_SAVE_CLICKED:
			return saveClicked(R.clone(state), action);
		case Types.JOBS_ADD_SUCCEEDED:
			return addSucceeded(R.clone(state), action);
		case Types.JOBS_CLOSE_CLICKED:
			return closeClicked(R.clone(state), action);
		case Types.JOBS_SAVE_QUEUED:
			return saveQueued(R.clone(state), action);

		// on change
		case Types.JOBS_TITLE_CHANGED:
			return jobTitleChanged(R.clone(state), action);
		case Types.JOBS_DATE_CHANGED:
			return jobDateChanged(R.clone(state), action);
		case Types.JOBS_TYPE_CHANGED:
			return jobTypeChanged(R.clone(state), action);
		case Types.JOBS_CUSTOMER_ID_CHANGED:
			return jobCustomerIdChanged(R.clone(state), action);
		case Types.JOBS_SITE_ID_CHANGED:
			return jobSiteIdChanged(R.clone(state), action);
		case Types.JOBS_STAFF_ID_CHANGED:
			return jobStaffIdChanged(R.clone(state), action);
		case Types.JOBS_NOTES_CHANGED:
			return jobNotesChanged(R.clone(state), action);

		// fetch Photo list
		case Types.JOBS_PAGED_LIST_REQUESTED:
			const nextState = hydrateList(R.clone(state), action);
			return updateFlags(nextState, { fetch: "processing" });
		case Types.JOBS_LIST_REQUESTED:
			return getJobList(R.clone(state), action);
		case Types.JOBS_LIST_RECEIVED:
			return jobListReceived(R.clone(state), action);
		case Types.JOBS_LIST_FAILED:
			return jobListFailed(R.clone(state), action);

		// delete Photo
		case Types.JOBS_REMOVE_CLICKED:
			return removeClicked(R.clone(state), action);
		case Types.JOBS_REMOVE_SUCCEEDED:
			return jobRemoveSucceeded(R.clone(state), action);
		case Types.JOBS_REMOVE_FAILED:
			return updateFlags(R.clone(state), { delete: "error" });

		case Types.JOBS_SEARCH_CHANGED:
			return searchChanged(R.clone(state), action);
		case Types.JOBS_SEARCH_CLEAR:
			return searchClear(R.clone(state), action);

		case Types.FILE_CHANGED:
			return fileChanged(R.clone(state), action);

		case Types.SET_GLOBAL_MESSAGE:
			return globalMessageChanged(R.clone(state), action)

		default:
			return state;
	}
};
